<template>
  <ion-page>
    <ion-content fullscreen>
      <TheHeaderLarge/>
      <TheEvent :event="state.event"/>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import TheEvent from "@/components/TheEvent.vue";
import TheHeaderLarge from "@/components/TheHeaderLarge.vue";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    TheHeaderLarge,
    TheEvent,
    IonContent,
    IonPage
  },
  setup() {
    const { state } = useStore()
    const event = ref({})

    console.log(event)
    return {
      state,
      event
    };
  },
});
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
