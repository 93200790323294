<template>
  <ion-grid>
    <ion-card>
      <ion-card-header>
        <ion-card-title v-html="event.nombre" />
      </ion-card-header>
      <ion-card-content>
        <div v-html="event.descripcion" />
      </ion-card-content>
    </ion-card>
  </ion-grid>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonGrid,
} from "@ionic/vue";
import { EventShow } from "@/interfaces/EventShow";

export default defineComponent({
  components: {
    IonGrid,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
  },
  props: {
    event: Object as () => EventShow
  }
})
</script>

<style scoped>
p{
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 1em;
}

ion-card-title{
  font-size: 1.3rem;
}
</style>