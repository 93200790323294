
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import TheEvent from "@/components/TheEvent.vue";
import TheHeaderLarge from "@/components/TheHeaderLarge.vue";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    TheHeaderLarge,
    TheEvent,
    IonContent,
    IonPage
  },
  setup() {
    const { state } = useStore()
    const event = ref({})

    console.log(event)
    return {
      state,
      event
    };
  },
});
