

import { defineComponent } from "vue";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonGrid,
} from "@ionic/vue";
import { EventShow } from "@/interfaces/EventShow";

export default defineComponent({
  components: {
    IonGrid,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
  },
  props: {
    event: Object as () => EventShow
  }
})
